<template>
  <div class="body" >
    <div class="container" v-if="!islogin">
        <div class="form-box">
          <Register v-if="shift" @getData='loginSuccess'/>
          <Login v-if="!shift" @getData='loginSuccess' @changes="show"/>
        </div>
        <ConboxLeft @changeShift="Shift"></ConboxLeft>
        <ConboxRight @changeShift="Shift"></ConboxRight>
    </div>
    <div class="contain" v-if="islogin">
    <div class="main">
      <div class="head shadow">
        <span>
          <!-- <el-image style="margin-top:0px;height:50px;" :src="circleUrl" fit="scale-down"/> -->
          <img style="width:427.4px;height:50px;margin-top:0px;margin-left:30px" src="https://files.epis2048.net/zuul/items/logo2.png">
        </span>
        <span class="button"><el-button type="danger" @click="logout">退出登录</el-button></span>
        <span class="username">{{user_info.nickname}}</span>
      </div>
      <div class="right_container shadow">
        <p class="room">房间</p>
        <p class="room">当前房间：{{current_room.name}}</p>
        <p class="room">{{current_room.description}}</p>
        <div class="tips iconfont">
            <p>TIPS：</p>
            <p>&#xe60f; - 向上移动</p>
            <p>&#xe65d; - 向下移动</p>
            <p>&#xe625; - 向左移动</p>
            <p>&#xe624; - 向右移动</p>
            <p>&#xe724; - 捡拾物品</p>
            <p>&#xebb6; - 丢弃物品</p>
            <p>&#xe617; 若有问题请联系：</p>
            <p>&#xe631; zuul@epis2048.net</p>
        </div>
      </div>
      <div class="package shadow">
        <p>背包</p>
        <p>容量：{{user_info.total_weight}} / {{user_info.weight_upline}}</p>
        <div class="packageElem" v-for="(item,index) in user_info.items" :key="index">
          <span @mouseover="showDescription(item.description)">{{item.name + ' 重量:' + item.weight + " "}}</span>
          <el-popconfirm title="Are you sure to drop this?" @confirm="drop(item.id)">
          <template #reference>  
            <button>drop</button>
          </template>
        </el-popconfirm>
        </div>
      </div>
      <div class="game shadow" v-loading="loading">
        <div class="door top" v-if="current_room.north_exit"></div>
        <div class="door bottom" v-if="current_room.south_exit"></div>
        <div class="r-door left" v-if="current_room.west_exit"></div>
        <div class="r-door right" v-if="current_room.east_exit"></div>
        <div class="random" v-if="current_room.random_room"></div>
      </div>
      <div id="player" class="playerright"></div>
      <div class="item" v-for="item in current_room.items" :key="item.id" :id="item.id" :style="{background: `url('` + item.url + `') 100% 100% /100% no-repeat`}"></div>
      <div class="item cookie" v-if="current_room.magic_cookie"></div>
      <div class="bottom_container shadow">
        <div class="logs iconfont" v-for="item in logs" :key="item.id">
          &#xe670;{{ item.createTime}}&nbsp;&nbsp;&nbsp;&#xe613;{{item.msg }}
          <br>
        </div>
      </div>
    </div>
  </div>
  </div>
  
</template>

<script>
import axios from 'axios'
import Login from './components/login.vue'
import Register from './components/register.vue'
import ConboxLeft from './components/conbox-left.vue'
import ConboxRight from './components/conbox-right.vue'
import { ElMessage, ElMessageBox } from 'element-plus'


export default {
  name: 'App',
  components: {
    Login,
    Register,
    ConboxLeft,
    ConboxRight
  },
  data () {
    return {
      islogin: false,
      shift: false,
      circleUrl: 'https://files.epis2048.net/zuul/items/logo2.png',
      token: '',
      user_info: {items: null, nickname: "用户名", total_weight: 0, weight_upline: 0},
      current_room: {
      ID: 1,
			name: "",
			descripetion: "",
			north_exit: false,
			south_exit: false,
			east_exit: false,
			west_exit: false,
			random_room: false,
			magic_cookie: true,
      item: [],
      players: []
      },
      logs: [],
      loading: true
    }
  },
  methods: {
    getStatus(){
      let http = axios.create()
      this.loading = true
      // http.interceptors.response.use(
      // (res) => {
      //    if (res.data.code == 0) {
      //     return res.data;
      //    } else {
      //         alert(res.data.msg);
      //    }
      // },
      // (error) => { 
      //   alert("网络异常,请稍后再试");
      // }
      // )
      http({
        method: 'get',
        url: '/api/status',
        headers: {
          AccessToken: this.token
        }
      }).then(data => {
        if(data.status === 200){
          if(data.data.code == -1) {
            ElMessageBox.alert('账号已经在别处登录！', {
            confirmButtonText: 'OK',
          })
          this.token = ''
          localStorage.removeItem('token')
          this.islogin = false
          } else {
            this.user_info = data.data.data.user_info
            this.current_room = data.data.data.current_room
            this.logs = data.data.data.logs
            this.loading = false
            this.$nextTick(()=>{
            this.random_item()
          })
          }
          
        }
        
      })
    },
    loginSuccess(data){
      this.islogin = true
      this.token = data
      localStorage.setItem('token',data)
      this.getStatus()
    },
    Shift(){
      this.shift = !this.shift
      let form = document.getElementsByClassName('form-box')[0]
      if(this.shift){
        form.style.transform = 'translateX(103%)'
      } else {
        form.style.transform = 'translateX(0%)'
      }
    },
    logout(){
      this.islogin = false
      this.token = ''
      localStorage.removeItem('token')
    },
    throttle(src){
      let that =this
      let timer =null
      return function (direction) {
        if(timer) return
        timer = () => {
          let http = axios.create()
          http({
           method: 'put',
           url: '/api/'+ src,
           headers: {
             AccessToken: that.token
           },
           params: {
             direction: direction
           }
          }).then(data => {
          if(data.status === 200){
            that.getStatus()
            timer =null
            let player = document.getElementById('player')
            player.style.left = '50%'
            player.style.top = '50%'
          }
          })
        }
        timer()
      }
    },
    random_item(){
      let items = document.getElementsByClassName('item')
        for(let i =0; i < items.length; i++){
          let x = Math.floor(Math.random() * (80 - 15 + 1)) + 15
          let y = Math.floor(Math.random() * (73 - 8 + 1)) + 8
          items[i].style.position = 'absolute'
          items[i].style.top = y +'%'
          items[i].style.left = x +'%'
        }
    },
    pick(id){
      let http = axios.create()
      http({
        method: 'put',
        url: '/api/pick',
        headers: {
          AccessToken: this.token
        },
        params: {
             item_id: id
        }
      }).then(data => {
        if(data.status === 200){
          this.getStatus()
        } 
      })
    },
    drop(id){
      let http = axios.create()
      http({
        method: 'put',
        url: '/api/drop',
        headers: {
          AccessToken: this.token
        },
        params: {
             item_id: id
        }
      }).then(data => {
        if(data.status === 200){
          this.getStatus()
        } 
      })
    },
    tp(){
      let http = axios.create()
      http({
        method: 'put',
        url: '/api/tp',
        headers: {
          AccessToken: this.token
        }
      }).then(data => {
        if(data.status === 200){
          this.getStatus()
        } 
      })
    },
    eat(){
      let http = axios.create()
      http({
        method: 'put',
        url: '/api/eat',
        headers: {
          AccessToken: this.token
        }
      }).then(data => {
        if(data.status === 200){
          this.getStatus()
        } 
      })
    },
    showDescription(item){
      ElMessage({
        message: item,
        type: 'success',
        showClose: true,
  })
    }
  },
  created(){
    localStorage.getItem('token')
    if(localStorage.getItem('token')){
      this.token = localStorage.getItem('token')
      this.islogin = true
      this.getStatus()
    }
    let that = this
    let move = that.throttle('move')
    let tp = that.throttle('tp')
      document.onkeydown = function (){
        if(that.islogin){
          let player = document.getElementById('player')
          let items =document.getElementsByClassName('item')
          if(event.key === 'ArrowDown'){
            if(player.style.top === ''){
              player.style.top = '50%'
            }
            if(player.style.left === ''){
              player.style.left = '50%'
            }
            if(parseFloat(player.style.top) < 73)
            player.style.top = parseFloat(player.style.top) + 1 +'%'
          } else if(event.key === 'ArrowUp'){
            if(player.style.top === ''){
              player.style.top = '50%'
            }
            if(player.style.left === ''){
              player.style.left = '50%'
            }
            if(parseFloat(player.style.top) > 8)
            player.style.top = parseFloat(player.style.top) - 1 +'%'
          } else if(event.key === 'ArrowLeft'){
            if(player.style.left === ''){
              player.style.left = '50%'
            }
            if(player.style.top === ''){
              player.style.top = '50%'
            }
            player.className = 'playerleft'
            if(parseFloat(player.style.left) > 15)
            player.style.left = parseFloat(player.style.left) - 1 +'%'
          } else if(event.key === 'ArrowRight'){
            if(player.style.left === ''){
              player.style.left = '50%'
            }
            if(player.style.top === ''){
              player.style.top = '50%'
            }
            player.className = 'playerright'
            if(parseFloat(player.style.left) < 83)
            player.style.left = parseFloat(player.style.left) + 1 +'%'
          } else if(event.key === 'Enter'){
            for(let i=0;i<items.length;i++){
              if(Math.abs(parseFloat(player.style.top) - parseFloat(items[i].style.top)) < 4){
                if(Math.abs(parseFloat(player.style.left) - parseFloat(items[i].style.left)) < 2){
                  if(items[i].id){
                    that.pick(items[i].id)
                  } else {
                    that.eat()
                  }
                  
                }
              }
            }
          }
          if(parseFloat(player.style.left) >= 48 && parseFloat(player.style.left) <= 50){
            if(parseFloat(player.style.top) == 8){
              if(that.current_room.north_exit){
                move('north')
              }
            } else if(parseFloat(player.style.top) >= 68){
              if(that.current_room.south_exit){
                move('south')
              }
            }
          }
          if(parseFloat(player.style.top) >= 39 && parseFloat(player.style.top) <= 45){
            if(parseFloat(player.style.left) <= 19){
              if(that.current_room.west_exit){
                move('west')
              }
              
            } else if(parseFloat(player.style.left) >= 79){
              if(that.current_room.east_exit){
                move('east')
              }
            }
          }
          if(parseFloat(player.style.left) >= 80 && parseFloat(player.style.top) >= 60){
            if(that.current_room.random_room){
              tp()
            }
          }
        }
      
    }
  }
}
</script>

<style>
@import "assets/index.css";

*{
  margin: 0;
  padding: 0;
}
.body{
    /* 100%窗口高度 */
    height: 100vh;
    /* 弹性布局 水平+垂直居中 */
    display: flex;
    justify-content: center;
    align-items: center;
    /* 渐变背景 */
    background: linear-gradient(200deg,#f3e7e9,#e3eeff);
}
.container{
    background-color: #fff;
    width: 700px;
    height: 450px;
    border-radius: 5px;
    /* 阴影 */
    box-shadow: 5px 5px 5px rgba(0,0,0,0.1);
    /* 相对定位 */
    position: relative;
}
.form-box{
    /* 绝对定位 */
    position: absolute;
    top: -10%;
    left: 3%;
    background-color: #d3b7d8;
    width: 320px;
    height: 550px;
    border-radius: 5px;
    box-shadow: 2px 0 10px rgba(0,0,0,0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    /* 动画过渡 加速后减速 */
    transition: 0.5s ease-in-out;
}
.form-box button{
    width: 70%;
    margin-top: 35px;
    background-color: #f6f6f6;
    outline: none;
    border-radius: 8px;
    padding: 13px;
    color: #a262ad;
    letter-spacing: 2px;
    border: none;
    cursor: pointer;
}
.form-box button:hover{
    background-color: #a262ad;
    color: #f6f6f6;
    transition: background-color 0.5s ease;
}
#player{
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10%;
  height: 10%;
  z-index: 1;
}
.playerleft{
  width: 10%;
  height: 10%;
  background: url('./assets/playerleft.png') no-repeat;
}
.playerright{
  width: 10%;
  height: 10%;
  background: url('./assets/player.png') no-repeat;
}
.contain{
  width: 100vw;
  height: 100vh;
}
.main{
  width: 100vw;
  height: 100vh;
  background-color: #eee;
}
.head{
  width: 100vw;
  height: 50px;
  background-color: white;
}
.icon {
  float: left;
  margin:0;
}
.username {
  float: right;
  margin: 14px 30px 0 10px;
  color: #a262ad;
}
.button{
  float: right;
  margin: 7px 35px 0 0;
  color:#fff;
}
.game{
  width: 70%;
  height: 70%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-60%);
  background-color: #fff;
  border-radius: 5px;
}
.top{
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%,0);
}
.bottom{
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%,0);
}
.left{
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0,-50%);
}
.right{
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0,-50%);
}
.door{
  width: 7%;
  height: 7%;
  padding-bottom:30px;
  background-image: url('./assets/door.png');
  background-size: 100%;
}
.r-door{
  width: 7%;
  height: 7%;
  padding-bottom:30px;
  background-image: url('./assets/door.png');
  background-size: 100%;
}
.random{
  position: absolute;
  bottom: 0%;
  right: 0%;
  width: 8%;
  height: 8%;
  padding-bottom:40px;
  background-image: url('./assets/tp.png');
  background-size: 100%;
}
.package{
  width: 12%;
  height: 70%;
  margin: 0.5% 0 0 2%;
  background-color: white;
  border-radius: 5px;
}
.package p{
  padding: 10px 20px;
  border-bottom: 1px solid rgb(201,201,201);
  font-size: 14px;
  color:#666;
}
.package button{
    background-color: #f6f6f6;
    outline: none;
    border-radius: 8px;
    padding: 13px;
    color: #a262ad;
    letter-spacing: 2px;
    border: none;
    cursor: pointer;
}
.package button:hover{
    background-color: #a262ad;
    color: #f6f6f6;
    transition: background-color 0.5s ease;
}
.packageElem{
  padding: 5px 15px; 
  border-bottom: 1px solid rgb(201,201,201);
  font-size: 14px;
  color:#666;
  cursor: pointer;
  text-align: center;
}
.packageElem:hover{
  transition:0.3s ease;
  transform: translateY(-2px);
}
.packageElem span{
  color: #a262ad;;
}
.packageElem button{
  padding:8px 10px;
}
.item{
  width: 2%;
  height: 5%;
  position: absolute;
  background: blue;
}
.logs{
  color: #a262ad;
}
.list_item{
  margin: 5px 0;
}
.cookie{
  background: url('./assets/cookie.png') no-repeat;
  background-size: 105%;
}
.right_container{
  position:relative;
  float: right;
  width: 12%;
  height: 70%;
  margin: 0.5% 2% 0 0;
  background-color: white;
  border-radius: 5px;
}
.right_container .room{
  padding: 10px 20px;
  border-bottom: 1px solid rgb(201,201,201);
  font-size: 14px;
  color:#666;
}
.right_container .tips{
  position:absolute;
  bottom:0;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 11px;
  /* color:#666; */
  border-top: 1px solid rgb(201,201,201);
  text-align: left;
}
.right_container .tips p{
  padding-bottom:5px;
}
.bottom_container{
  padding: 10px 20px;
  height: 15%;
  margin: 1% 2% 0 2%;
  background-color: white;
  border-radius: 5px;
  overflow: scroll;
  border-top: 1px solid rgb(201,201,201);
  border-left: 1px solid rgb(201,201,201);
}
.shadow{
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
}

</style>
