<template>
  <div class="login">
    <h1>login</h1>
    <input v-model="id" placeholder="请输入账号" />
    <input v-model="psw" type="password" placeholder="请输入密码" show-password @keydown.enter="login"/>
    <button type="primary" plain size='large' @click="login">登录</button>
  </div>
</template>

<script>
import axios from 'axios'
import {  ElMessageBox } from 'element-plus'
export default {
  name: 'login',
  data () {
    return {
      id: '',
      psw: ''
    }
  },
  methods: {
    login () {
      if(this.id !== '' && this.psw !== ''){
        let http = axios.create()
      http({
        method: 'post',
        url: '/api/login',
        data: 
          {
          "username": this.id,
          "password": this.psw
        }
      }).then(data => {
        if(data.data.code === 0) {
          this.$emit('getData',data.data.data.access_token)
          ElMessageBox.alert('登录成功', {
          confirmButtonText: 'OK',
        })
        } else if(data.data.code === -1){
          ElMessageBox.alert('账号或密码错误', {
          confirmButtonText: 'OK',
        })
        }
      })
      } else {
        ElMessageBox.alert('请输入账号密码', {
          confirmButtonText: 'OK',
        })
      }
    },
    changeShow () {
      this.$emit('changes',true)
    }
  }
}
</script>


<style  scoped>
.login{
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 300px;
  padding: 100px;
  border-radius: 20px;
  text-align: center;
  background-color: #fff; */

  /* 弹性布局 垂直排列 */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
h1{
    text-align: center;
    margin-bottom: 25px;
    /* 大写 */
    text-transform: uppercase;
    color: #fff;
    /* 字间距 */
    letter-spacing: 5px;
}
/* 输入框渲染 */
input{
    background-color: transparent;
    width: 70%;
    color: #fff;
    border: none;
    /* 下边框样式 */
    border-bottom: 1px solid rgba(255,255,255,0.4);
    padding: 10px 0;
    text-indent: 10px;
    margin: 8px 0;
    font-size: 14px;
    letter-spacing: 2px;
}
input::placeholder{
    color: #fff;
}
input:focus{
    color: #a262ad;
    outline: none;
    border-bottom: 1px solid #a262ad80;
    transition: 0.5s;
}
input:focus::placeholder{
    opacity: 0;
}
/* 按钮 */
button{
    width: 70%;
    margin-top: 35px;
    background-color: #f6f6f6;
    outline: none;
    border-radius: 8px;
    padding: 13px;
    color: #a262ad;
    letter-spacing: 2px;
    border: none;
    cursor: pointer;
}
button:hover{
    background-color: #a262ad;
    color: #f6f6f6;
    transition: background-color 0.5s ease;
}
</style>
