<template>
  <div class="register">
    <h1>register</h1>
    <input v-model="id" placeholder="请输入账号" />
    <input v-model="nickname" placeholder="请输入游戏昵称" />
    <input v-model="psw" type="password" placeholder="请输入密码" show-password/>
    <input v-model="psw2" type="password" placeholder="请再次输入密码" show-password/>
    <input v-model="email" placeholder="请输入邮箱" />
    <button type="primary" plain size='large' @click="register">注册</button>
  </div>
</template>

<script>
import {  ElMessageBox } from 'element-plus'
import axios from 'axios'
export default {
  name: 'register',
  data () {
    return {
      id: '',
      psw: '',
      psw2: '',
      nickname: '',
      email: ''
    }
  },
  methods: {
    register () {
      if(this.id === ''){
        this.id = ''
      } else if(this.psw === this.psw2){
        let http = axios.create()
        http({
          method: 'post',
          url: '/api/register',
          data: 
            {
            'username': this.id,
            'nickname': this.nickname,
            'password': this.psw,
            'email': this.email
          }
        }).then(data => {
          if(data.data.code === 0){
            ElMessageBox.alert('注册成功', {
            confirmButtonText: 'OK',
          })
           http({
            method: 'post',
            url: '/api/login',
            data: 
              {
              'username': this.id,
              'password': this.psw
            }
          }).then(data => {
            if(data.data.code === 0) {
              this.$emit('getData',data.data.data.access_token)
            }
          })
          } else {
            ElMessageBox.alert('注册失败', {
            confirmButtonText: 'OK',
          })
          }
        })
        } else {
          ElMessageBox.alert('密码不一致', {
            confirmButtonText: 'OK',
          })
          this.psw2= ''
        }
      
    }
  }
}
</script>

<style scoped>
.register{
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 300px;
  padding: 100px;
  border-radius: 20px;
  text-align: center;
  background-color: #fff;
  z-index: 1; */
  
  /* 弹性布局 垂直排列 */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

}
.hidden{
  display: none;
  transition: 0.5s;
}
h1{
  text-align: center;
  margin-bottom: 25px;
  /* 大写 */
  text-transform: uppercase;
  color: #fff;
  /* 字间距 */
  letter-spacing: 5px;
}
/* 输入框渲染 */
input{
  background-color: transparent;
  width: 70%;
  color: #fff;
  border: none;
  /* 下边框样式 */
  border-bottom: 1px solid rgba(255,255,255,0.4);
  padding: 10px 0;
  text-indent: 10px;
  margin: 8px 0;
  font-size: 14px;
  letter-spacing: 2px;
}
input::placeholder{
  color: #fff;
}
input:focus{
  color: #a262ad;
  outline: none;
  border-bottom: 1px solid #a262ad80;
  transition: 0.5s;
}
input:focus::placeholder{
  opacity: 0;
}
/* 按钮 */
button{
    width: 70%;
    margin-top: 35px;
    background-color: #f6f6f6;
    outline: none;
    border-radius: 8px;
    padding: 13px;
    color: #a262ad;
    letter-spacing: 2px;
    border: none;
    cursor: pointer;
}
button:hover{
    background-color: #a262ad;
    color: #f6f6f6;
    transition: background-color 0.5s ease;
}
</style>