<template>
    <div class="conbox left">
        <img src="../assets/zuul-logo.jpg" alt="">
        <h2>Welcome to <span>Zuul</span></h2>
        <p>快来创建你的<span>角色</span>吧</p>
        <p>已有账号</p>
        <button @click="changeToLogin">去登录</button>
    </div>
</template>

<script>
export default {
  methods: {
    changeToLogin(){
        this.$emit('changeShift')
    }
  }
}
</script>

<style>
.conbox{
    width: 50%;
    /* 弹性布局 垂直排列 居中 */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* 绝对定位 居中 */
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.left{
    left: -2%;
}
h2{
    color: #8e9aaf;
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 3px;
    text-align: center;
    margin-bottom: 4px;
}
p{
    font-size: 12px;
    letter-spacing: 2px;
    color: #8e9aaf;
    text-align: center;
}
span{
    color: #d3b7d8;
}
img{
    width: 220px;
    height: 60px;
    opacity: 0.9;
    margin: 40px 0;
}
button{
    margin-top: 3%;
    background-color: #fff;
    color: #a262ad;
    border: 1px solid #d3b7d8;
    padding: 6px 10px;
    border-radius: 5px;
    letter-spacing: 1px;
    outline: none;
    cursor: pointer;
}
button:hover{
    background-color: #d3b7d8;
    color: #fff;
}
</style>
